import React from 'react';
import { DefaultType } from 'types/default';

import Slider, { SliderType } from 'components/molecules/slider';
import Typography from 'components/atoms/typography';

import { breakpoints } from 'hooks/use-screen-service';

import {
  StyledAuthor,
  StyledAuthorPosition,
  StyledCard,
  StyledContent,
  StyledDotsGridTwo,
  StyledIcon,
  StyledText,
  StyledTitle,
  StyledWrapper,
} from './opinions.styles';

export type OpinionType = {
  author: string;
  position: string;
  text: string;
};

export type OpinionsType = {
  subTitle: string;
  heading: string;
  opionionsList: OpinionType[];
} & DefaultType;

const Opinions = ({ subTitle, heading, opionionsList }: OpinionsType) => {
  const cardsSlides = opionionsList.map(({ author, position, text }) => (
    <StyledCard>
      <StyledIcon name="quote" />
      <StyledText variant="title6" color="gray80">
        {text}
      </StyledText>
      <StyledAuthor>
        <Typography variant="title4">{author}</Typography>
        <StyledAuthorPosition>{position}</StyledAuthorPosition>
      </StyledAuthor>
    </StyledCard>
  ));
  const cardSliderOptions: SliderType['options'] = {
    slidesPerView: 1.2,
    spaceBetween: 16,
    watchSlidesProgress: true,
    scrollbar: { draggable: true },
    breakpoints: {
      [breakpoints.sm]: {
        slidesPerView: 2.2,
      },
      [breakpoints.md]: {
        slidesPerView: 2.5,
      },
    },
  };

  return (
    <StyledWrapper>
      <StyledDotsGridTwo width={2} height={26} color="whiteAlpha40" />
      <StyledContent>
        <Typography html={subTitle} variant="title4" color="gray40" />
        <StyledTitle html={heading} variant="title2" component="h2" color="white" />
        <Slider
          {...{
            slides: cardsSlides,
            options: cardSliderOptions,
            variant: 'primary',
          }}
        />
      </StyledContent>
    </StyledWrapper>
  );
};

export default Opinions;
