import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import DotsGrid from 'components/molecules/dots-grid';
import Icon from 'components/atoms/icon';
import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div<DefaultType>`
  position: relative;
  overflow: hidden;

  padding: ${({ theme, mobilePadding }) => mobilePadding || `${theme.gap.md} ${theme.gap.sm}`};
  width: 100%;
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.complementary1};

  ${breakpoint.md} {
    margin-block: ${({ theme, margin }) => margin || `${theme.gap.xl} 0`};
    padding: ${({ theme, padding }) => padding || `${theme.gap.xl} 0`};
  }
`;

export const StyledContent = styled.div`
  max-width: ${({ theme }) => theme.wrapper};
  width: 100%;
  margin: 0 auto;
  height: 100%;

  .swiper {
    display: flex;
    align-items: flex-end;
    position: relative;
    width: fill-available;

    &-scrollbar {
      background-color: ${({ theme }) => theme.colors.whiteAlpha40};
      height: 2px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      width: calc(100% - 16px);
      position: relative;
      left: 0;
      top: 0;
      order: 3;
      margin-right: auto;
      cursor: pointer;

      &-drag {
        cursor: pointer;
        height: 4px;
        background-color: ${({ isWhite, theme }) =>
          isWhite ? `${theme.colors.gray80}` : `${theme.colors.white}`};
        border-radius: 0;
      }
    }

    &-horizontal > &-scrollbar {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &-wrapper {
      order: 2;
      margin-block: ${({ theme }) => theme.gap.md};
      min-height: 340px;
      height: 100% !important;

      ${breakpoint.md} {
        min-height: 350px;
        height: 100%;
        margin-block: 58px ${({ theme }) => theme.gap.lg};
      }
    }

    &-slide {
      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .slider-controls__wrapper {
      ${breakpoint.md} {
        margin-top: 58px;
        justify-content: end;
      }
    }

    .slider-controls__button {
      align-items: flex-end;
      margin-top: ${({ theme }) => theme.gap.sm};

      ${breakpoint.md} {
        margin-top: ${({ theme }) => theme.gap.md};
      }
    }
  }
`;

export const StyledTitle = styled(Typography)`
  margin-block: ${({ theme }) => theme.gap.sm} 0;

  ${breakpoint.md} {
    position: absolute;
    max-width: 825px;
    margin-top: 16px;
  }
`;

export const StyledBarWrapper = styled.div`
  height: 4px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.whiteAlpha40};
`;

export const StyledBar = styled.div<{ width?: number }>`
  margin-top: ${({ theme }) => theme.gap.lg};
  height: 100%;
  width: ${({ width }) => `${width}%`};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const StyledCard = styled.div`
  padding: ${({ theme }) => theme.gap.md};
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 283px;
`;

export const StyledText = styled.div`
  margin-top: ${({ theme }) => theme.gap.smd};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray80};
`;

export const StyledAuthor = styled.div`
  margin-top: ${({ theme }) => theme.gap.smd};
`;

export const StyledAuthorPosition = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray80};
`;

export const StyledDotsGridTwo = styled(DotsGrid)<{ rowLayout?: boolean }>`
  display: none;
  z-index: 1;

  ${breakpoint.xl} {
    display: block;
    left: 30px;
    top: 15px;
  }
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary50};
`;
